<template>
  <div class="ma-5">
    <lawyer-administration />
  </div>
</template>

<script>
import LawyerAdministration from "@/components/admin/LawyerAdministration";
export default {
  name: "AdminInterface",
  components: {LawyerAdministration}
}
</script>

<style scoped>

</style>
