<template>
  <v-card flat>
    <v-toolbar>
      <v-tabs
          v-model="tab"
          align-with-title
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab
            v-for="t in tabs"
            :key="t.name"
        >
          {{ t.name }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="t in tabs"
            :key="t"
        >
          <v-card flat>
            <v-card-text>
              <div class="sticky-headers">
                <v-data-table
                    :headers="t.header"
                    :items="lawyers"
                    class="elevation-1"
                >
                  <template v-for="permission in t.header.filter((item, index) => index > 1)" v-slot:[`item.`+permission.value]="{ item }">
                    <v-checkbox
                        v-bind:key="permission.value"
                        v-model="item[permission.value.split('.')[0]][permission.value.split('.')[1]]"
                        @change="updateLawyer(item)"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>

import {sessionHandler} from "@/request/sessionHandler";
import {getLawyersURL, getLawyerURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "LawyerAdministration",
  data: () => ({
    lawyers: [],
    tab: null,
    tabOfTab: null,
    tabs: [
      {name: "Alle", header: [
          {
            text: 'Vorname',
            align: 'start',
            value: 'firstname',
            width: '100px'
          },
          {
            text: 'Nachname',
            align: 'start',
            value: 'lastname',
            width: '100px'
          },
          { text: 'Advocard Business | Telefonberatung', class: "primary", value: 'advocardBusinessPermissions.Telefonberatung', align: 'start',  },
          { text: 'Advocard International | Telefonberatung', class: "primary lighten-1", value: 'advocardInternationalPermissions.Telefonberatung',  align: 'start' },
          { text: 'Advocard International | Dokumentencheck', class: "primary lighten-1", value: 'advocardInternationalPermissions.Dokumentencheck',  align: 'start' },
          { text: 'Advocard International | Vermittlung Mediation', class: "primary lighten-1", value: 'advocardInternationalPermissions.VermittlungMediation',  align: 'start' },
          { text: 'Advocard International | Mediation', class: "primary lighten-1", value: 'advocardInternationalPermissions.Mediation',  align: 'start' },
          { text: 'Advocard International | Vermittlung Coaching', class: "primary lighten-1", value: 'advocardInternationalPermissions.VermittlungCoaching',  align: 'start' },
          { text: 'Advocard International | Coaching', class: "primary lighten-1", value: 'advocardInternationalPermissions.Coaching',  align: 'start' },
          { text: 'Advocard Private | Telefonberatung', class: "primary darken-1", value: 'advocardPrivatePermissions.Telefonberatung', align: 'start' },
          { text: 'Advocard Private | Dokumentencheck', class: "primary darken-1", value: 'advocardPrivatePermissions.Dokumentencheck',  align: 'start' },
          { text: 'Advocard Private | Vermittlung Mediation', class: "primary darken-1", value: 'advocardPrivatePermissions.VermittlungMediation',  align: 'start' },
          { text: 'Advocard Private | Mediation', class: "primary darken-1", value: 'advocardPrivatePermissions.Mediation',  align: 'start' },
          { text: 'Advocard Private | Vermittlung Coaching', class: "primary darken-1", value: 'advocardPrivatePermissions.VermittlungCoaching',  align: 'start' },
          { text: 'Advocard Private | Coaching', class: "primary darken-1", value: 'advocardPrivatePermissions.Coaching',  align: 'start' },
          { text: 'Advocard Private | Vermittlung Fachberatung', class: "primary darken-1", value: 'advocardPrivatePermissions.VermittlungFachberatung',  align: 'start' },
          { text: 'Advocard Private | Fachberatung', class: "primary darken-1", value: 'advocardPrivatePermissions.Fachberatung',  align: 'start' },
          { text: 'Advocard Private | Chevalier Vermittlung', class: "primary darken-1", value: 'advocardPrivatePermissions.ChevalierVermittlung',  align: 'start' },
          { text: 'Arag | Abwesend', class: "yellow darken-4", value: 'aragPermissions.Override',  align: 'start' },
          { text: 'Arag | Telefonberatung', class: "yellow darken-4", value: 'aragPermissions.Telefonberatung',  align: 'start' },
          { text: 'Arag | Telefonberatung 24/7', class: "yellow darken-4", value: 'aragPermissions.Telefonberatung247',  align: 'start' },
          { text: 'Arag | Erstberatung mit Dokumentenprüfung', class: "yellow darken-4", value: 'aragPermissions.Dokumentenpruefung',  align: 'start' },
          { text: 'Arag | Online Beratung', class: "yellow darken-4", value: 'aragPermissions.OnlineBeratung',  align: 'start' },
          { text: 'Arag | Vertragscheck', class: "yellow darken-4", value: 'aragPermissions.Vertragscheck',  align: 'start' },
          { text: 'Arag | Manager Rechtsschutz', class: "yellow darken-4", value: 'aragPermissions.ManagerRechtsschutz',  align: 'start' },
          { text: 'Arag | Strafrechtshotline', class: "yellow darken-4", value: 'aragPermissions.Strafrechtshotline',  align: 'start' },
          { text: 'Arag | Vermittlung Erstberatung Versicherungspruefung', class: "yellow darken-4", value: 'aragPermissions.ErstberatungVersicherungspruefungVermittlung',  align: 'start' },
          { text: 'Arag | Erstberatung Versicherungspruefung', class: "yellow darken-4", value: 'aragPermissions.ErstberatungVersicherungspruefung',  align: 'start' },
          { text: 'Arag | Erstberatung Versicherungspruefung Priorisierung', class: "yellow darken-4", value: 'aragPermissions.ErstberatungVersicherungspruefungFallback',  align: 'start' },
          { text: 'Arag | Vermittlung Prüfung Anstellungsvertrag', class: "yellow darken-4", value: 'aragPermissions.PruefungAnstellungsvertragVermittlung',  align: 'start' },
          { text: 'Arag | Prüfung Anstellungsvertrag', class: "yellow darken-4", value: 'aragPermissions.PruefungAnstellungsvertrag',  align: 'start' },
          { text: 'Arag | Prüfung Anstellungsvertrag Priorisierung', class: "yellow darken-4", value: 'aragPermissions.PruefungAnstellungsvertragFallback',  align: 'start' },
          { text: 'Roland | Telefonberatung', class: "blue", value: 'rolandPermissions.Telefonberatung',  align: 'start' },
          { text: 'MPU | MPU Beratung', class: "pink", value: 'mpuPermissions.MPU',  align: 'start'        ,    width: '100px' },
        ]},
      {name: "ARAG", header: [
          {
            text: 'Vorname',
            align: 'start',
            value: 'firstname',
            width: '100px'
          },
          {
            text: 'Nachname',
            align: 'start',
            value: 'lastname',
            width: '100px'
          },
          { text: 'Arag | Abwesend', class: "yellow darken-4", value: 'aragPermissions.Override',  align: 'start' },
          { text: 'Arag | Telefonberatung', class: "yellow darken-4", value: 'aragPermissions.Telefonberatung',  align: 'start' },
          { text: 'Arag | Telefonberatung 24/7', class: "yellow darken-4", value: 'aragPermissions.Telefonberatung247',  align: 'start' },
          { text: 'Arag | Erstberatung mit Dokumentenprüfung', class: "yellow darken-4", value: 'aragPermissions.Dokumentenpruefung',  align: 'start' },
          { text: 'Arag | Online Beratung', class: "yellow darken-4", value: 'aragPermissions.OnlineBeratung',  align: 'start' },
          { text: 'Arag | Vertragscheck', class: "yellow darken-4", value: 'aragPermissions.Vertragscheck',  align: 'start' },
          { text: 'Arag | Manager Rechtsschutz', class: "yellow darken-4", value: 'aragPermissions.ManagerRechtsschutz',  align: 'start' },
          { text: 'Arag | Strafrechtshotline', class: "yellow darken-4", value: 'aragPermissions.Strafrechtshotline',  align: 'start' },
          { text: 'Arag | Vermittlung Erstberatung Versicherungspruefung', class: "yellow darken-4", value: 'aragPermissions.ErstberatungVersicherungspruefungVermittlung',  align: 'start' },
          { text: 'Arag | Erstberatung Versicherungspruefung', class: "yellow darken-4", value: 'aragPermissions.ErstberatungVersicherungspruefung',  align: 'start' },
          { text: 'Arag | Erstberatung Versicherungspruefung Priorisierung', class: "yellow darken-4", value: 'aragPermissions.ErstberatungVersicherungspruefungFallback',  align: 'start' },
          { text: 'Arag | Vermittlung Prüfung Anstellungsvertrag', class: "yellow darken-4", value: 'aragPermissions.PruefungAnstellungsvertragVermittlung',  align: 'start' },
          { text: 'Arag | Prüfung Anstellungsvertrag', class: "yellow darken-4", value: 'aragPermissions.PruefungAnstellungsvertrag',  align: 'start' },
          { text: 'Arag | Prüfung Anstellungsvertrag Priorisierung', class: "yellow darken-4", value: 'aragPermissions.PruefungAnstellungsvertragFallback',  align: 'start' },
          ]},
      {name: "Advocard", header: [
          {
            text: 'Vorname',
            align: 'start',
            value: 'firstname',
            width: '100px'
          },
          {
            text: 'Nachname',
            align: 'start',
            value: 'lastname',
            width: '100px'
          },
          { text: 'Advocard Business | Telefonberatung', class: "primary", value: 'advocardBusinessPermissions.Telefonberatung', align: 'start',  },
          { text: 'Advocard International | Telefonberatung', class: "primary lighten-1", value: 'advocardInternationalPermissions.Telefonberatung',  align: 'start' },
          { text: 'Advocard International | Dokumentencheck', class: "primary lighten-1", value: 'advocardInternationalPermissions.Dokumentencheck',  align: 'start' },
          { text: 'Advocard International | Vermittlung Mediation', class: "primary lighten-1", value: 'advocardInternationalPermissions.VermittlungMediation',  align: 'start' },
          { text: 'Advocard International | Mediation', class: "primary lighten-1", value: 'advocardInternationalPermissions.Mediation',  align: 'start' },
          { text: 'Advocard Private | Telefonberatung', class: "primary darken-1", value: 'advocardPrivatePermissions.Telefonberatung', align: 'start' },
          { text: 'Advocard Private | Dokumentencheck', class: "primary darken-1", value: 'advocardPrivatePermissions.Dokumentencheck',  align: 'start' },
          { text: 'Advocard Private | Vermittlung Mediation', class: "primary darken-1", value: 'advocardPrivatePermissions.VermittlungMediation',  align: 'start' },
          { text: 'Advocard Private | Mediation', class: "primary darken-1", value: 'advocardPrivatePermissions.Mediation',  align: 'start' },
          { text: 'Advocard Private | Vermittlung Fachberatung', class: "primary darken-1", value: 'advocardPrivatePermissions.VermittlungFachberatung',  align: 'start' },
          { text: 'Advocard Private | Fachberatung', class: "primary darken-1", value: 'advocardPrivatePermissions.Fachberatung',  align: 'start' },
        ]},
      {name: "Roland", header: [
          {
            text: 'Vorname',
            align: 'start',
            value: 'firstname',
            width: '100px'
          },
          {
            text: 'Nachname',
            align: 'start',
            value: 'lastname',
            width: '100px'
          },
           { text: 'Roland | Telefonberatung', class: "blue", value: 'rolandPermissions.Telefonberatung',  align: 'start'        ,    width: '100px' },
        ]},
      {name: "MPU", header: [
          {
            text: 'Vorname',
            align: 'start',
            value: 'firstname',
            width: '100px'
          },
          {
            text: 'Nachname',
            align: 'start',
            value: 'lastname',
            width: '100px'
          },
          { text: 'MPU | MPU Beratung', class: "pink", value: 'mpuPermissions.MPU',  align: 'start'        ,    width: '100px' },
        ]},
    ]
  }),
  methods: {
    async updateLawyer(item){
      this.isLoading = true;
      //  if Session isValid
      await sessionHandler();
      await this.$http
          .patch(
              getLawyerURL(item.username),
              item
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then(() => {})
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE4", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
      // putLawyer(item.lawAreas ,item.lawyer.userKeycloakUUID).then(() => {
      //   this.getAllLawyers()
      // })
    },
    async getAllLawyers(){
      this.isLoading = true;
        //  if Session isValid
        await sessionHandler();
        await this.$http
            .get(
                getLawyersURL()
                , {
                  mode: requestMode(),
                  headers: {
                    'Accept': 'application/json',
                    'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                  },
                }
            )
            .then((response) => {
              if (response.status === 200) {
                this.lawyers = response.data
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  showAlert(i18n.t('warning.unauthorised'), "warning");
                }
              } else {
                showAlert(i18n.t('error.api.undefined') + "Calendar-gE4", "error");
              }
            })
            .finally(() => {
              this.isLoading = false;
            })
      }
  },
  mounted() {
    this.getAllLawyers()
  }
}
</script>

<style lang="sass">
.sticky-headers table > tbody > tr > td:nth-child(1),
.sticky-headers table > thead > tr > th:nth-child(1)
  position: sticky !important
  position: -webkit-sticky !important
  left: 0
  z-index: 9998
  background: var(--v-background-base)

.sticky-headers table > thead > tr > th:nth-child(1)
  z-index: 9999


.sticky-headers table > tbody > tr > td:nth-child(2),
.sticky-headers table > thead > tr > th:nth-child(2)
  position: sticky !important
  position: -webkit-sticky !important
  left: 100px
  z-index: 9998
  background: var(--v-background-base)

.sticky-headers table > thead > tr > th:nth-child(2)
  z-index: 9999

</style>
